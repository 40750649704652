.container {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #ecf0f1;
}

.loging_container {
    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    -webkit-box-shadow: 0px 0px 8px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 8px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 8px -1px rgba(0,0,0,0.75);
}

form {
    display: block !important;
    margin: 0 auto;
    padding-top: 5rem;
}

.loging_container h1 {
    padding: 2rem;
    color: rgba(0, 0, 0, 0.8);
}

.loging_container hr {
    width: 95%;
    margin: 0 auto;
}

.loging_container button{
    float: right;
    margin: 1rem 5% 2rem 0;
}

.loging_container p {
    margin-bottom: 2rem;
    font-size: 1.4rem;
    text-align: center;
    color: rgba(17, 17, 17, 0.849);
}

@media(max-width : 48em){ /* 768 / 16 */

    html {
        font-size: 53.12%;
    }

    .loging_container {
        width: 70%;
    }

}

@media(max-width : 37.5em){ /* 600px / 16 */

    html {
        font-size: 46.88%;
    }

    .loging_container {
        width: 90%;
    }

}