.btn_sm {
    padding: .8rem 10%;
    cursor: pointer;
    outline: none;
    user-select: none;
}

.btn_lg {
    padding: .8rem 20%;
    cursor: pointer;
    outline: none;
    user-select: none;
}

.btn_orange_light {
    background-color: #f3b34e;
    border: 1px solid #e69e4b;
    color: white;
}

.btn_orange_light:hover {
    background-color: #f39c12;
    border: 1px solid #e6810e;
    color: white;
}

.btn_orange {
    background-color: #f39c12;
    border: 1px solid #e6810e;
    color: white;
}

.btn_orange:hover {
    background-color: #d47910;
    border: 1px solid #c46f0d;
}