*,
*::before,
*::after {
    margin: 0;
    padding: 0;
}

/* width */
::-webkit-scrollbar {
    width: 1%;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(185, 184, 184); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(128, 128, 128); 
}

.clear {
    clear: both;
    float: none !important;    
}

html {
    font-size: 62.5%;
    scrollbar-color: rgb(185, 184, 184) #f1f1f1;
    scrollbar-width: thin;
}

body {
    box-sizing: border-box;
}

ul {
    list-style: none;
}

h1, h2, h3, h4, h5 {
    font-family: sans-serif;
}

a {
    text-decoration: none;
}

.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
}

.col-3 {
    width: 24.99%;
}

.col-4 {
    width: 33.32%;
}

.col-5 {
    width: 41.65%;
}

.col-6 {
    width: 49.98%;
}

.col-7 {
    width: 58.31%;
}

.col-8 {
    width: 66.64%;
}

.col-9 {
    width: 74.97%;
}

.col-10 {
    width: 83.3%;
}

.col-11 {
    width: 91.63%;
}

.col-12 {
    width: 100%;
}

.offset-1 {
    margin-left: 8.33%;
}

.offset-2 {
    margin-left: 16.66%;
}

.offset-3 {
    margin-left: 24.99%;
}

.offset-4 {
    margin-left: 33.32%;
}

.offset-5 {
    margin-left: 41.65%;
}

.offset-6 {
    margin-left: 49.98%;
}

.offset-7 {
    margin-left: 58.31%;
}

.offset-8 {
    margin-left: 66.64%;
}

.offset-9 {
    margin-left: 74.97%;
}

.offset-10 {
    margin-left: 83.3%;
}

.offset-11 {
    margin-left: 91.63%;
}

.offset-12 {
    margin-left: 100%;
}

@media(max-width : 75em){ /* 1200 / 16 */

    html {
        font-size: 56.25%;
    }

}

@media(max-width : 56.25em){ /* 900 / 16 */

    html {
        font-size: 53.12%;
    }

}
