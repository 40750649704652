.home_area {
    width: 100%;
    height: auto;
    display: table;
}

.home_content {
    width: 80%;
    height: calc(100vh - 6rem);
    vertical-align: top;
    background-color: #fbfbfb;
    display: table-cell;
    position: relative;
}

.clip_board_container {
    display: inline-block;
    vertical-align: top;
    color: #808080;
    font-family: Arial, Helvetica, sans-serif;
}

.home_content_heading {
    width: 100%;
    height: 5rem;
    background-color: #f39c12;
    position: relative;
}

.home_content_heading h2 {
    width: 80%;
    float: left;
    background-repeat: no-repeat;
    background-position: 2% 50%;
    background-size: 3.5rem 3.5rem;
    padding: 1rem 0 1rem 6rem;
    color: white;
    font-size: 2.5rem;
    font-weight: 100;
    vertical-align: top;
    font-family: serif;
}

.home_content_placehold {
    width: 60%;
    margin: 0 auto;
}

.error_message {
    height: 1.6rem;
    text-align: left !important;
    margin-bottom: unset !important;
    margin-top: 1rem;
}

@media(max-width : 56.25em){ /* 900 / 16 */

    .home_area {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        transform: translateY(-1px);
        position: relative;
    }

    .home_content {
        width: 100%;
        /* height: calc(100vh - 6rem); */
        height: auto;
        display: inline-block;
        white-space: normal;
        /* transform: translateX(-50%); */
        transition: all .3s ease-in-out;
    }

    .home_content_placehold {
        width: 70%;
        margin: 0 auto;
    }

    .navigation_togle {
        transform: translateX(0%) !important;
        -webkit-box-shadow: 2px 0px 10px -3px rgba(0,0,0,0.77);
        -moz-box-shadow: 2px 0px 10px -3px rgba(0,0,0,0.77);
        box-shadow: 2px 0px 10px -3px rgba(0,0,0,0.77);
    }

}

@media(max-width : 48em){ /* 768 / 16 */

    .home_content_placehold {
        width: 80%;
        margin: 0 auto;
    }

}

@media(max-width : 37.5em){ /* 600px / 16 */

    .home_content_placehold {
        width: 90%;
        margin: 0 auto;
    }

}
