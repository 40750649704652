header {
    width: 100%;
    height: 6rem;
    background-color: #354051;
    z-index: 1000;
    display: table-caption;
}

.hamberg_menu {
    width: 4rem;
    height: 4rem;
    padding: 1rem 1rem 1rem 3rem;
    display: none;
}

.hamberg_menu span {
    width: 100%;
    height: .5rem;
    display: block;
    margin: .6rem 0;
    background-color: #d2d2d2;
    border-radius: 5px;
    transform-origin: left;
    transform: rotate(0deg) translateY(0);
    visibility: visible;
    opacity: 1;
    transition: all .3s ease-in-out;
}

.logo {
    width: 20%;
    display: inline-block;
    font-size: 2.4em;
    padding-top: 1.5rem;
    text-align: center;
    color: #d2d2d2;
    vertical-align: top;
}

header > nav {
    display: block;
    float: right;
    margin-right: 4%;
}

.top_menu > ul > li {
    width: 7rem;
    float: left;
    text-align: center;
    display: list-item;
    position: relative;
    cursor: pointer;
}

.top_menu > ul > li > a,
.top_menu > ul > li > div {
    width: 100%;
    display: inline-block;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 3.6rem;
    padding-top: 4.2rem;
}

.top_menu > ul > li > a > h5,
.top_menu > ul > li > div > h5 {
    font-size: 1.33em;
    color: #d2d2d2;
}

@media(max-width : 56.25em){ /* 900 / 16 */

    header { 
        display: block;
    }

    .logo {
        display: none;
    }

    .hamberg_menu {
        display: inline-block;
    }

    .hamberg_menu_first {
        transform: rotate(45deg) translateY(-.5rem) !important;
    }

    .hamberg_menu_middle {
        opacity: 0 !important;
        visibility: hidden !important;
    }
    
    .hamberg_menu_last {
        transform: rotate(-45deg) translateY(0.5rem) !important;
    }    
    
}