.navigation {
    width: 20%;
    background-color: #ecf0f1;
    font-family: sans-serif;
    z-index: 500;
    display: table-cell;
}

.agent_detail {
    width: 100%;
    display: block;
    padding: 2rem 0;
}

.agent_detail div {
    width: 100%;
    padding-bottom: 1.5rem;
}

.agent_detail img {
    width: 14rem;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}

.agent_detail h4 {
    font-size: 1.4em;
    line-height: 1.7;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
}

.agent_detail h5 {
    font-size: 1.2em;
    line-height: 1.7;
    text-align: center;
    color: rgba(0, 0, 0, 0.35);
}

.navigation_active {
    background-color: white;
}

.navigation a {
    width: auto;
    height: auto;
    color: rgba(101, 101, 101, 0.78);
    font-size: 1.6rem;
}

.navigation li {
    padding: .8rem 0 .8rem 2.6rem;
    display: block;
}
.popup-arrow {
    height:  1rem !important;
}


@media(max-width : 56.25em){ /* 900 / 16 */

    .navigation {
        width: 50%;
        height: calc(100vh - 6rem);
        display: inline-block;
        white-space: normal;
        transform: translateX(-100%);
        position: fixed;
        transition: all .3s ease-in-out;   
    }

}