.portal-form {
    display: inline-block;
}

.portal-form .form-group {
    padding: 1rem 0 0 .5rem;
}

.left-padding-remove {
    padding: 1rem 0 0 0 !important;
}

.portal-form .form-group label,
.portal-form .form-group .custom-select {
    display: inline-block;
    font-size: 1.4rem;
    color: #000000d6;
    font-family: sans-serif;
}

.portal-form .form-group .custom-select {
    position: relative;
    padding: 0 !important;
}

.portal-form .form-group .custom-select select {
    display: none; /*hide original SELECT element:*/
}

.portal-form .form-group .custom-select .select-items div,
.portal-form .form-group .custom-select .select-selected {
    color: black;
    padding: .8rem 1.6rem;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
}

.portal-form .form-group .custom-select .select-selected {
    background-color: white;
}

.portal-form .form-group .custom-select .select-selected:after {
    position: absolute;
    content: "";
    top: 1.4rem;
    right: 1rem;
    width: 0;
    height: 0;
    border: .6rem solid transparent;
    border-color: #000 transparent transparent transparent;
    transition: all .3s ease-in-out;
}

.portal-form .form-group .select-selected.select-arrow-active:after {
    border-color: transparent transparent #000 transparent;
    top: 7px;
}

.portal-form .form-group .custom-select .select-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: -.1rem;
    right: -.1rem;
    z-index: 99;
    height: 15rem;
    overflow : auto;
    border : 1px solid #9c9c9cba;
    transition: all .3s ease-in-out;
}

.portal-form .form-group .custom-select .select-hide {
    height: 0;
    border: none !important;
}

.portal-form .form-group .custom-select .select-items div:hover, 
.portal-form .form-group .custom-select .same-as-selected {
    background-color: #3498db;
    color: white;
}
  

.portal-form .form-group input[type="text"], 
.portal-form .form-group input[type="password"], 
.portal-form .form-group input[type="date"], 
.portal-form .form-group input[type="email"], 
.portal-form .form-group input[type="file"], 
.portal-form .form-group input[type="number"], 
.portal-form .form-group input[type="time"],
.portal-form .form-group .custom-select {
    padding: .8rem 0 .8rem 1rem;
    border: 1px solid #9c9c9cba;
    font-family: sans-serif;
    color: #000000d6;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
}

.portal-form .form-group input[type="text"]:focus, 
.portal-form .form-group input[type="password"]:focus, 
.portal-form .form-group input[type="date"]:focus, 
.portal-form .form-group input[type="email"]:focus, 
.portal-form .form-group input[type="file"]:focus, 
.portal-form .form-group input[type="number"]:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
}
